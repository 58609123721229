$( document ).ready(function() {
    console.log( "ready!" );
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="dropdown"]').dropdown()
   
    

    $(window).resize(function() {
        mainhResize();
        if ($(window).width() > 1600) {
            $('header [data-toggle="dropdown"]').attr('data-hover', 'dropdown').removeAttr('data-toggle');
        } else {
            if ($(window).width() <= 1600) { // 假设768px为移动设备的阈值
                $('.dropdown-toggle').attr('data-toggle', 'dropdown');
            }
        }
    });
    if ($(window).width() <= 1600) { // 假设768px为移动设备的阈值
        $('.dropdown-toggle').attr('data-toggle', 'dropdown');
    }

    $('.sticky-wrapper').stickynav();
    //$("header").sticky();
    let isMenuAlreadyOpen = false;
    $('.navbar-toggler').on('click',()=>{
            $('body').css("overflow",isMenuAlreadyOpen?"auto":"hidden")
            isMenuAlreadyOpen = !isMenuAlreadyOpen
    })
     //Check to see if the window is top if not then display button
     $('.scrollToTop').fadeOut();
     $(window).scroll(function(){
        if ($(this).scrollTop() > 100) {
            $('.scrollToTop').fadeIn();
        } else {
            $('.scrollToTop').fadeOut();
        }
    });
    
    //Click event to scroll to top
    $('.scrollToTop').click(function(){
        AOS.refresh();
        AOS.init({
            once: true,
            duration: 700
        })
        $('html, body').animate({scrollTop : 0},800);
        return false;
    });
    function  mainhResize(){
              console.log('resize');
              //var headerh = $("header").height();
              var headerh = 0;
              var footerh = $("footer").height();
              var windowh = $(window).height();
              var contactTitle = $(".page-header").height();
              var mainh = windowh - footerh;
              $('main').css("min-height", mainh + 'px');
              $('.full-height').css("min-height", (windowh) + 'px');
              $('.auto-height').css("min-height", (windowh - headerh ) + 'px');
              $('.kv-height').css("min-height", (windowh - headerh ) + 'px');
              //$("header").sticky();
              
           //$('.contact-content').css("min-height", (mainh) + 'px');
     } 
      $(window).resize(function()
         {
              mainhResize()
         });
     mainhResize()
     $('.main-menu a[href*=\\#]').on('click',function(e) {

        var target = this.hash;
        var $target = $(target);
        console.log(targetname);
        var targetname = target.slice(1, target.length);
    
        if(document.getElementById(targetname) != null) {
             e.preventDefault();
        }
        $('html, body').stop().animate({
            'scrollTop': $target.offset().top //or the height of your fixed navigation 
    
        }, 900, 'swing', function () {
            window.location.hash = target;
      });
    }); 
    $('.nav-item.dropdown.lang').hover(
        function() {
            $(this).addClass('show'); // Add class 'show' on mouse enter
            $(this).find('.dropdown-menu').addClass('show'); // Add class 'show' to dropdown-menu
        }, 
        function() {
            $(this).removeClass('show'); // Remove class 'show' on mouse leave
            $(this).find('.dropdown-menu').removeClass('show'); // Remove class 'show' from dropdown-menu
        }
    );
    $('.nav-item.dropdown.main-menu').hover(
        function() {
            $(this).addClass('show'); // Add class 'show' on mouse enter
            $(this).find('.dropdown-menu').addClass('show'); // Add class 'show' to dropdown-menu
        }, 
        function() {
            $(this).removeClass('show'); // Remove class 'show' on mouse leave
            $(this).find('.dropdown-menu').removeClass('show'); // Remove class 'show' from dropdown-menu
        }
    );
    AOS.init({
        //once: true,
        anchorPlacement: 'center-center',
        duration: 1500,
    })
});

$(document).ready(function () {
    // 获取当前页面的 URL
    const currentUrl = window.location.href;

    // 用正则表达式提取语言部分（en/tc/sc）
    const langRegex = /\/(en|tc|sc)\//;
    const match = currentUrl.match(langRegex);
    const currentLang = match ? match[1] : "en"; // 默认语言为 "en"

    // 替换语言部分的方法
    function replaceLangInUrl(url, targetLang) {
        if (langRegex.test(url)) {
            // 如果 URL 已经有语言部分，替换它
            return url.replace(langRegex, `/${targetLang}/`);
        } else {
            // 如果 URL 没有语言部分，默认在 `/main/` 后添加目标语言
            return url.replace("/main/", `/main/${targetLang}/`);
        }
    }

    // 动态设置语言切换链接
    $(".lang-en").attr("href", replaceLangInUrl(currentUrl, "en"));
    $(".lang-tc").attr("href", replaceLangInUrl(currentUrl, "tc"));
    $(".lang-sc").attr("href", replaceLangInUrl(currentUrl, "sc"));

    // 根据当前语言高亮显示
    $(`.lang-${currentLang}`).addClass("d-none");
});

$(document).ready(function () {
  // Select all image elements
  $('img').each(function () {
    // Get the current 'src' attribute of the image
    var src = $(this).attr('src');

    // Append a version parameter to the 'src' to bypass cache
    // Use a timestamp (or random number) to make the URL unique
    var newSrc = src + (src.includes('?') ? '&' : '?') + 'v=' + new Date().getTime();

    // Update the 'src' attribute with the new URL
    $(this).attr('src', newSrc);
  });

  // Example: If you want to do the same for CSS or JS files
  $('link[rel="stylesheet"]').each(function () {
    var href = $(this).attr('href');
    var newHref = href + (href.includes('?') ? '&' : '?') + 'v=' + new Date().getTime();
    $(this).attr('href', newHref);
  });

  $('script').each(function () {
    var src = $(this).attr('src');
    if (src) {
      var newSrc = src + (src.includes('?') ? '&' : '?') + 'v=' + new Date().getTime();
      $(this).attr('src', newSrc);
    }
  });

    window.addEventListener('load', () => {
        // 移除隐藏类
        document.body.classList.remove('hidden');
        document.body.classList.add('loaded');
    });
});